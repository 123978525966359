const initialState = {
  account: {
    select_date: new Date(),
  },
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SELECT_DATE":
      return { ...state, account: action.data };

    default:
      return state;
  }
};

export default accountReducer;
