const initialState = {
  customerData: {
    name: "",
    address: "",
    postNum: "",
    customerFormatType: "1",
    phoneNumber: "",
    fullCustomerData: "",
  },
  orderData: {
    OrderNumber: 0,
    shippingCost: 0,
    discount: 0,
    otherCost: 0,
    CODFee: 0,
    DeliveryType: {
      value: null,
      label: "",
      type: "",
    },
    OrderStatus: "",
    OrderStatusDisplay: "",
    printAddrData: {
      IsPrintAddr: 0,
      printId: "",
      printAddrDate: "",
      printBy: "",
    },
  },
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CUSTOMER_DATA":
      return {
        ...state,
        customerData: action.data,
      };
    case "SET_ORDER_DATA":
      return {
        ...state,
        orderData: action.data,
      };
    case "CLEAR_ORDER_DATA":
      return {
        ...initialState,
      };
    default:
      return { ...state };
  }
};
export default orderReducer;
