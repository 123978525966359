const initialState = {
  products: [],
  wishlist: [],
  cart: [],
  productDetail: {},
  params: {},
  totalProducts: 0,
  ProductOrders: [],
};

const ecommerceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PRODUCTS":
      return {
        ...state,
        products: action.data.products,
        params: action.params,
        totalProducts: action.data.total,
      };
    case "GET_WISHLIST":
      return { ...state, wishlist: action.data.products };
    case "DELETE_WISHLIST_ITEM":
      return { ...state };
    case "GET_CART":
      return { ...state, cart: action.data.products };
    case "DELETE_CART_ITEM":
      return { ...state };
    case "ADD_TO_WISHLIST":
      return { ...state };
    case "ADD_TO_CART":
      return { ...state };
    case "GET_PRODUCT":
      return { ...state, productDetail: action.data.product };
    case "ADD_TO_ORDER":
      // console.log('action.data', action.data);
      // console.log('state.ProductOrders', state.ProductOrders);
      let indexOfStevie = state.ProductOrders.findIndex((i) => {
        // console.log(i.uq)
        return i.uq == action.data.uq;
      });
      // console.log('indexOfStevie2', indexOfStevie);
      if (indexOfStevie === -1) {
        return {
          ...state,
          ProductOrders: [...state.ProductOrders, action.data],
        };
      }
      let t = [...state.ProductOrders];
      t[indexOfStevie].Quantity = parseInt(action.data.Quantity);
      return { ...state, ProductOrders: t };
    case "ADD_TO_ORDER_ONCHANGE":
      // console.log('action.data', action.data);
      // console.log('state.ProductOrders', state.ProductOrders);
      console.log("dUQ", action.data.uq);
      let indexOfStevie2 = state.ProductOrders.findIndex((i) => {
        console.log("iUQ", i.uq);
        return i.uq == action.data.uq;
      });
      // console.log('indexOfStevie2', indexOfStevie);
      console.log("Add2>>>>");
      if (indexOfStevie2 === -1) {
        console.log("Add>>>>");
        return {
          ...state,
          ProductOrders: [...state.ProductOrders, action.data],
        };
      }
      let t2 = [...state.ProductOrders];
      t2[indexOfStevie2].Quantity = parseInt(action.data.Quantity);
      return { ...state, ProductOrders: t2 };

    case "EDIT_ORDER":
      if (state.ProductOrders.indexOf(action.data) === -1) {
        return { ...state, ProductOrders: [...action.data] };
      }
      return state;
    case "CLEAR_PRODUCT":
      return { ...initialState };

    case "SET_PRODUCT_ORDER_DATA":
      return { ...state, ProductOrders: action.data };

    default:
      return state;
  }
};

export default ecommerceReducer;
